import React, { useRef } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import image1 from "./images/1.jpg";
import image2 from "./images/2.jpg";
import image3 from "./images/3.jpg";
import image1S from "./images/1Small.jpg";
import image2S from "./images/2Small.jpg";
import image3S from "./images/3Small.jpg";
import "./HomePage.css";

// import { HomePageContainer } from "./HomePageElements";

const HomePageSection = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const sliderRef = useRef(null); // Create a ref for the slider

  const handleButtonClick = (id) => {
    navigate(`/Prodaja/${id}`);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ul style={{ margin: "0", padding: "0", display: "flex" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="custom-dot"
        style={{
          width: "15px",
          height: "3px",
          background: "rgba(255, 255, 255, 0.5)",
          margin: "0 5px",
        }}
      />
    ),
  };

  return (
    <div id="home" className="home-page-container">
      <Slider className="slider" ref={sliderRef} {...settings}>
        <div className="slide-content">
          <div className="text-content">
            <h1>DJI AGRAS T50</h1>
            <p>Moćan, Precizan, Inovativan</p>
            <button
              className="learn-more-button"
              onClick={() => handleButtonClick("DJI-Agras-T50")}
            >
              Saznaj Više
            </button>
          </div>
          <img className="responsive-image image1" alt="DJI AGRAS T50" />
        </div>
        <div className="slide-content">
          <div className="text-content">
            <h1>DJI AGRAS T25</h1>
            <p>Manji, Inteligentniji i Snažniji</p>
            <button
              className="learn-more-button"
              onClick={() => handleButtonClick("DJI-Agras-T25")}
            >
              Saznaj Više
            </button>
          </div>
          <img className="responsive-image image2" alt="DJI AGRAS T25" />
        </div>
        <div className="slide-content">
          <div className="text-content">
            <h1>DJI MAVIC 3M</h1>
            <p>Vidi više, Radi pametnije</p>
            <button
              className="learn-more-button"
              onClick={() => handleButtonClick("DJI-Mavic-3M")}
            >
              Saznaj Više
            </button>
          </div>
          <img className="responsive-image image3" alt="DJI MAVIC 3M" />
        </div>
      </Slider>
      <div
        className="custom-arrow custom-arrow-prev"
        onClick={() => sliderRef.current.slickPrev()}
      />
      <div
        className="custom-arrow custom-arrow-next"
        onClick={() => sliderRef.current.slickNext()}
      />
    </div>
  );
};

export default HomePageSection;

{
  /* <HomeBackground>
        <ImageBackground />
      </HomeBackground> */
}
{
  /* <HomeContent>
        <MarkoKnjiga>
          <ImageWrapper>
            <ImageOfBook2 src={MarkoKnjigaImage} />
          </ImageWrapper>
          <PreNaslovKnjige>
            <SpanBookTitle>| </SpanBookTitle>KNJIGA
          </PreNaslovKnjige>
          <TitleOfBook>"PRECIZNA POLJOPRIVREDA"</TitleOfBook>
          <PUmderTitle>Prof. dr Marko Kostić</PUmderTitle>
          <PUmderTitle>Poljoprivredni Fakultet</PUmderTitle>
          <PUmderTitle>Novi Sad</PUmderTitle>
          <BigYellowButton onClick={handleButtonClick}>
            PREUZMITE PDF Besplatno
          </BigYellowButton>
        </MarkoKnjiga>
      </HomeContent> */
}
