import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import React from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  // NavLogoFax,
  NavLinksKontakt,
} from "./NavbarElements";

const toggleHome = () => {
  scroll.scrollToTop();
};

const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}></NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="/" onClick={toggleHome}>
                Naslovna
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/prodaja" onClick={toggleHome}>
                Prodaja
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/kursevi" onClick={toggleHome}>
                Kursevi
              </NavLinks>
            </NavItem>{" "}
            <NavItem>
              <NavLinks to="/usluge" onClick={toggleHome}>
                Usluge
              </NavLinks>
            </NavItem>{" "}
            <NavItem>
              <NavLinks to="/blog" onClick={toggleHome}>
                Blog
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/onama" onClick={toggleHome}>
                O nama
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinksKontakt
                to="footer"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                Kontakt
              </NavLinksKontakt>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
